<template lang="pug">
svg(xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.047" height="13.047" viewBox="0 0 13.047 13.047")
  defs
    clipPath(id="clip-path")
      path(id="Path_11" data-name="Path 11" d="M0,0H13.047V-1H0Z" fill="none")
  g(id="Group_31" data-name="Group 31" transform="translate(-934 -550.335)")
    g(id="Group_21" data-name="Group 21" transform="translate(934 557.359)")
      g(id="Group_19" data-name="Group 19" clip-path="url(#clip-path)")
        g(id="Group_18" data-name="Group 18" transform="translate(0 -0.5)")
          path(id="Path_10" data-name="Path 10" d="M0,0H13.047" fill="none" stroke="#000" stroke-width="1")
    g(id="Group_22" data-name="Group 22" transform="translate(940.023 550.335) rotate(90)")
      g(id="Group_19-2" data-name="Group 19" clip-path="url(#clip-path)")
        g(id="Group_18-2" data-name="Group 18" transform="translate(0 -0.5)")
          path(id="Path_10-2" data-name="Path 10" d="M0,0H13.047" fill="none" stroke="#000" stroke-width="1")
</template>

<script>
export default {
  name: 'Cart-Plus'
}
</script>
