<template lang="pug">
  footer.shop-footer-links.mt-72
    nav.h-48.flex.flex-wrap.items-center.justify-center.text-13.md-text-15.-mx-12
      //- nav.app-header__nav.w-7x18.xl-w-6x18.px-12.text-15.whitespace-no-wrap
      router-link(:to="{name: 'refunds'}").mr-2sp Refunds
      router-link(:to="{name: 'shipping'}").mr-2sp Shipping
      router-link(:to="{name: 'privacy'}").mr-2sp Privacy Policy
      router-link(:to="{name: 'terms'}") Terms of Service
</template>

<script>
export default {
  name: 'ShopFooterLinks'
}
</script>

<style>
</style>
