<template lang="pug">
  section.cart.min-h-screen.flex.flex-col.justify-between.pt-80.md-pt-144.px-26.md-px-80

    //- (has items)
    template(v-if="cart && cart.lineItems.length")
      div
        //- header
        header.flex.h-48.items-center.font-medium.text-16.border-b
          h1.md-w-1x3 Your Bag
          .hidden.md-block.md-w-1x3 Item
          .hidden.md-block.md-w-1x3 Price

        ul
          //- items...
          li.md-flex.border-b.py-24(v-for="(item, i) in cart.lineItems")

            //- image column
            figure.mb-16.md-mb-0.md-w-1x3
              resp-img.md-w-3x4.lg-w-2x3(:src="item.variant.image.originalSrc", :altText="item.variant.altText || `${item.title}: ${item.variant.title}`")

            //- title column
            div.md-flex.md-flex-col.md-justify-between.md-w-1x3.md-pr-32
              div
                h6.italic.font-medium.text-16.mb-12 {{ item.title }}
                //- p.italic.font-medium.text-12.md-text-15 {{ item.variant.title }}
                p.italic.text-15(v-if="descrip(item)", v-html="descrip(item)")

              //- qty / btns
              .flex.my-18.md-my-0
                .flex.border.md-border.rounded-lg.py-8
                  button(@click="updateItemQuantity(item, 1)", aria-label="Increase Quantity")
                    cart-plus.mx-14
                  .normal-nums.text-14.md-text-16 {{ item.quantity }}
                  button(@click="updateItemQuantity(item, -1)", aria-label="Decrease Quantity")
                    cart-minus.h-12.mx-14

            //- price column
            footer.flex.justify-between.md-w-1x3.md-items-start
              //- price
              .normal-nums.text-16 ${{ item.variant.priceV2.amount * item.quantity }} USD
              //- remove item btn
              button(@click="removeItem(item)", aria-label="Remove Item")
                svg-x.h-12.w-12.md-w-14.md-h-14(width="1")

        //- cart footer
        footer
          //- (mobile buttons)
          .flex.justify-between.h-48.flex.items-center.justify-between.border-b.md-hidden
            button.text-gray-500.font-medium.text-16(@click="clearCart") Clear Cart
            router-link.text-gray-500.font-medium.text-16(:to="{name: 'editions'}") Continue Shopping

          //- sub total
          div.h-48.flex.items-center.md-border-b.justify-between.md-justify-end.md-pt-2
            .w-full.flex.justify-between.md-w-1x3
              div.text-16.font-medium Subtotal
              .normal-nums.text-16.font-medium ${{ cart.subtotalPriceV2.amount }} {{ cart.subtotalPriceV2.currencyCode }}

          //- note
          //- div.md-flex.md-justify-end
            p.py-12.text-center.md-w-420.md-text-12 Shipping and taxes calculated during checkout

          //- checkout / btns
          div.w-full.md-flex.justify-end.md-mt-24
            //- (landscape buttons)
            .hidden.md-flex.md-w-1x3.md-items-center
              button.text-gray-500.font-medium.text-16(@click="clearCart") Clear Cart
              router-link.md-mx-auto.text-gray-500.font-medium.text-16(:to="{name: 'editions'}") Continue Shopping
            //- checkout btn
            a.rounded-lg.h-44.md-h-48.md-w-1x3.flex.items-center.justify-center.w-full.bg-black.text-white.font-medium.text-12.md-text-15.md-w-420(:href="cart.webUrl") Checkout Now

    //- (no items)
    template(v-else)
      .flex.h-48.justify-between.md-justify-start.items-center.font-medium.text-16
        p.md-w-1x3 Your bag is empty.
        router-link.md-w-1x3.text-gray-500(to="/editions") Continue Shopping

    //- footer links
    shop-footer-links

</template>

<script>
import { mapState } from 'vuex'
import RespImg from '@/components/RespImg'
import ShopFooterLinks from '@/components/ShopFooterLinks'
import CartPlus from '@/components/Icons/Cart-Plus.vue'
import CartMinus from '@/components/Icons/Cart-Minus.vue'
import svgX from '@/components/Icons/SVG-X.vue'
export default {
  name: 'Cart',
  components: { RespImg, CartPlus, CartMinus, svgX, ShopFooterLinks },
  computed: {
    ...mapState({
      cart: state => state.shop.checkout
    })
  },
  methods: {
    descrip (item) {
      return item.customAttributes.find(item => item.key === 'description')?.value
    },

    getLineItemIndex (target) {
      return this.cart.lineItems.findIndex(itm => itm.variant.id === target.variant.id)
    },

    async removeItem (item) {
      // find index of item
      const i = this.getLineItemIndex(item)
      //
      if (i > -1) {
        this.status = 'Removing...'
        const newItems = [...this.cart.lineItems]
        newItems.splice(i, 1)
        await this.$store.dispatch('shop/updateCheckoutItems', newItems)
        this.status = null
      }
    },

    async updateItemQuantity (item, dir = 1) {
      // ensure above 0
      const qty = Math.max(0, item.quantity + dir)

      // !! no change
      // if (qty === this.item.quantity) return

      // !! remove if 0
      if (qty === 0) return this.removeItem(item)

      // update
      const i = this.getLineItemIndex(item)

      if (i > -1) {
        // this.status = 'Updating...'

        // copy items
        let newItems = [...this.cart.lineItems]
        // format
        newItems = newItems.map(itm => ({ variantId: itm.variant.id, quantity: itm.quantity, customAttributes: item.customAttributes }))

        // change target item
        newItems[i].quantity = item.quantity + dir

        // update...
        await this.$store.dispatch('shop/updateCheckoutItems', newItems)

        // this.status = null
      }
    },

    async clearCart () {
      await this.$store.dispatch('shop/updateCheckoutItems', [])
    }
  }
}
</script>

<style>
</style>
