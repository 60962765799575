<template lang="pug">
svg(xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13.047" height="1" viewBox="0 0 13.047 1")
  defs
    clipPath(id="clip-path")
      path(id="Path_11" data-name="Path 11" d="M0,0H13.047V-1H0Z" fill="none")
  g(id="Group_20" data-name="Group 20" transform="translate(0 1)")
    g(id="Group_19" data-name="Group 19" clip-path="url(#clip-path)")
      g(id="Group_18" data-name="Group 18" transform="translate(0 -0.5)")
        path(id="Path_10" data-name="Path 10" d="M0,0H13.047" fill="none" stroke="#000" stroke-width="1")
</template>

<script>
export default {
  name: 'Cart-Minus'
}
</script>
